@import "../../../node_modules/bootstrap/scss/functions";
@import "../../../node_modules/bootstrap/scss/variables";
@import "../../../node_modules/bootstrap/scss/mixins";

@import '../../css/variables';

.component-references {
    padding-top: 0;
    padding-bottom: 40px;
    .container {
        line-height: 1;
        h3 {
            font-family: 'Lato-Semibold';
            font-size: .75rem;
            line-height: 1;
            color: $main-text-color;
            display: inline;
            padding-right: .5rem;
            font-weight: normal;
        }
        ol {
            padding: 0;
            margin: 0;
            counter-reset: item;
            line-height: 0;
            display: inline;
            li {
                display: inline;
                list-style-type: none;
                counter-increment: item;
                font-family: 'Lato-Light';
                font-size: .75rem;
                line-height: 1;
                color: $main-text-color;
                white-space: nowrap;
                &::before {
                    font-family: 'Lato-Semibold';
                    padding-right: 0.2em;
                    text-align: left;
                    content: counter(item) ".";
                }
                .text-break {
                    word-break: break-all !important;
                    overflow-wrap: break-word !important;
                }
                a {
                    color: inherit;
                    &:hover {
                        color: inherit;
                        text-decoration: none;
                    }
                }
                & > span {
                    margin-right: 0.25rem;
                    white-space: normal;
                }
            }
        }
    }
}
@include media-breakpoint-up(md) {
    .component-references {
        padding-top: 0;
        padding-bottom: 90px;
    }
}