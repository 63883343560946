@import "../../../node_modules/bootstrap/scss/functions";
@import "../../../node_modules/bootstrap/scss/variables";
@import "../../../node_modules/bootstrap/scss/mixins";

@import '../../css/variables';

$padding-horizontal-xs: 20px;
$padding-bottom-xs: 30px;
$color-green: #98ca3a;
.component-case-studies-carousel {
    background-color: #e9eff3;
    .header-holder {
        padding-left: $padding-horizontal-xs;
        padding-right: $padding-horizontal-xs;
        .label-holder {
            margin-bottom: 1rem;
            span {
                display: inline-block;
                &.title {
                    background-color: $color-green;
                    font-family: 'CeraPro-Bold';
                    font-size: 1rem;
                    color: #fff;
                    text-transform: uppercase;
                    padding: 7px 14px;
                    margin-right: 10px;
                    white-space: initial;
                }
                &.credits {
                    padding: 3px 0;
                    line-height: 1.25;
                }
            }
        }
        .component-accordion {
            border-bottom: 2px solid $color-green;
            .card {
                .card-header {
                    border-top: 2px solid $color-green;
                    padding: 0;
                    h2 {
                        button {
                            span {
                                color: $color-green;
                                font-family: 'Lato-Medium';
                            }
                        }
                    }
                    .expand-icon, .collapse-icon {
                        color: $color-green;
                        font-family: 'CeraPro-Thin';
                        font-size: 40px;
                    }
                }
                .card-body {
                    padding-left: 0;
                    padding-right: 0;
                    padding-bottom: 0;
                    h6 {
                        color: #64676c;
                        font-family: 'Lato-Medium';
                        margin-bottom: 0.5rem;
                    }
                    ul {
                        padding-top: 0;
                        padding-left: 1.35rem;
                        margin-bottom: 1.75rem;
                        li {
                            font-size: 1rem;
                            margin-bottom: 0;
                        }
                    }
                    p {
                        margin-bottom: 1.75rem;
                        font-size: 1rem;
                    }
                }
            }
        }
    }
    .carousel-inner {
        .carousel-item {
            padding-bottom: $padding-bottom-xs;
            padding-top: $padding-bottom-xs;
            padding-left: $padding-horizontal-xs;
            padding-right: $padding-horizontal-xs;
            .case-study-container {
                background-color: #fff;
                overflow: hidden;
                display: flex;
                flex-direction: column;
                border-radius: 5px;
                .image-holder {
                    order: 1;
                    img {
                        display: block;
                        width: 100%;
                    }
                }
                .data-holder {
                    order: 0;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    align-items: flex-start;
                    padding: 15px;
                    h5 {
                        font-size: 1.0625rem !important;
                        font-family: 'Lato-Bold';
                        text-transform: uppercase;
                        color: #65666b;
                        margin-bottom: 1.25rem;
                    }
                    h4 {
                        flex-grow: 2;
                        font-size: 1rem;
                        font-family: 'Lato-Bold';
                        margin-bottom: 1.25rem;
                    }
                    h6 {
                        font-family: 'Lato-Bold';
                        color: #65666b;
                        margin-bottom: 0;
                    }
                    p {
                        font-family: 'Lato-Light';
                        &:last-child {
                            margin-bottom: 0;
                        }
                    }
                    h6, p {
                        font-size: 0.875rem;
                    }
                }
            }
        }
    }
    .carousel-control-prev,
    .carousel-control-next {
        display: flex;
        opacity: 1;
        width: $padding-horizontal-xs;
        img {
            width: 10px;
        }
    }
}

$padding-horizontal-sm: 40px;
$padding-bottom-sm: 25px;
$case-study-container-haight-sm: 672px;
@include media-breakpoint-up(sm) {
    .component-case-studies-carousel {
        .header-holder {
            padding-left: $padding-horizontal-sm;
            padding-right: $padding-horizontal-sm;
            .component-accordion {
                .card {
                    .card-header {
                        h2 {
                            button {
                                span {
                                    font-size: 1.0625rem;
                                }
                            }
                        }
                    }
                    .card-body {
                        h6 {
                            font-size: 1.0625rem;
                        }
                        ul {
                            li {
                                font-size: 1.0625rem;
                            }
                        }
                        p {
                            font-size: 1.0625rem;
                        }
                    }
                }
            }
        }
        .carousel-inner {
            .carousel-item {
                padding-bottom: $padding-bottom-sm;
                padding-top: $padding-bottom-sm;
                padding-left: $padding-horizontal-sm;
                padding-right: $padding-horizontal-sm;
                .case-study-container {
                    height: $case-study-container-haight-sm;
                    .data-holder {
                        padding: 30px;
                    }
                }
            }
        }
        .carousel-control-prev,
        .carousel-control-next {
            width: $padding-horizontal-sm;
            img {
                width: 15px;
            }
        }
    }
}

$case-study-container-height-md: 287px;
@include media-breakpoint-up(md) {
    .component-case-studies-carousel {
        .header-holder {
            .label-holder {
                span {
                    &.title {
                        white-space: nowrap;
                    }
                }
            }
            .component-accordion {
                .card {
                    .card-header {
                        h2 {
                            button {
                                span {
                                    font-size: 1.1875rem;
                                }
                            }
                        }
                    }
                    .card-body {
                        h6 {
                            font-size: 1.1875rem;
                        }
                        ul {
                            li {
                                font-size: 1.1875rem;
                            }
                        }
                        p {
                            font-size: 1.1875rem;
                        }
                    }
                }
            }
        }
        .carousel-inner {
            .carousel-item {
                .case-study-container {
                    flex-direction: row;
                    border-radius: 10px;
                    height: $case-study-container-height-md;
                    .image-holder {
                        order: 0;
                        width: 60%;
                    }
                    .data-holder {
                        order: 1;
                        width: 40%;
                        padding: 15px;
                        h4 {
                            margin-bottom: 0;
                        }
                    }
                }
            }
        }
    }
}

$padding-horizontal-lg: 40px;
$padding-bottom-lg: 50px;
$case-study-container-haight-lg: 403px;
@include media-breakpoint-up(lg) {
    .component-case-studies-carousel {
        .header-holder {
            padding-left: $padding-horizontal-lg;
            padding-right: $padding-horizontal-lg;
        }
        .carousel-inner {
            .carousel-item {
                padding-bottom: $padding-bottom-lg;
                padding-top: $padding-bottom-lg;
                padding-left: $padding-horizontal-lg;
                padding-right: $padding-horizontal-lg;
                .case-study-container {
                    height: $case-study-container-haight-lg;
                    .data-holder {
                        padding: 30px;
                        h5 {
                            font-size: 1.45rem;
                        }
                        h4 {
                            margin-bottom: 0;
                            font-size: 1.35rem;
                        }
                        h6, p {
                            font-size: 1.15rem;
                        }
                    }
                }
            }
        }
        .carousel-control-prev,
        .carousel-control-next {
            width: $padding-horizontal-lg;
        }
    }
}

$case-study-container-haight-xl: 506px;
@include media-breakpoint-up(xl) {
    .component-case-studies-carousel {
        .header-holder {
            .component-accordion {
                .card {
                    .card-header {
                        h2 {
                            button {
                                span {
                                    font-size: 1.35rem;
                                }
                            }
                        }
                    }
                    .card-body {
                        h6 {
                            font-size: 1.35rem;
                        }
                        ul {
                            li {
                                font-size: 1.35rem;
                            }
                        }
                        p {
                            font-size: 1.35rem;
                        }
                    }
                }
            }
        }
        .carousel-inner {
            .carousel-item {
                .case-study-container {
                    height: $case-study-container-haight-xl;
                    .data-holder {
                        justify-content: flex-start;
                    }
                }
            }
        }
    }
}