@import "../../../node_modules/bootstrap/scss/functions";
@import "../../../node_modules/bootstrap/scss/variables";
@import "../../../node_modules/bootstrap/scss/mixins";

@import '../../css/variables';
.component-callout {
    font-family: 'Lato-Light';
    border-left: 2px solid #96c93d;
    padding: .5rem 0 .65rem 2rem;
    font-size: 1.375rem;
    margin-bottom: 0;
}
@include media-breakpoint-up(sm) {
    .component-callout {
        font-size: 1.5625rem;
    }
}
@include media-breakpoint-up(lg) {
    .component-callout {
        font-size: 1.75rem;
        padding: .5rem 9rem .65rem 2rem;
    }
}
@include media-breakpoint-up(xl) {
    .component-callout {
        padding: .5rem 16rem .65rem 2rem;
    }
}