@import "../../../node_modules/bootstrap/scss/functions";
@import "../../../node_modules/bootstrap/scss/variables";
@import "../../../node_modules/bootstrap/scss/mixins";

@import '../../css/variables';

.component-disclaimer {
    padding-top: 40px;
    padding-bottom: 40px;
    .container {
        text-align: center;
        font-size: 1.125rem;
    }
}
@include media-breakpoint-up(md) {
    .component-disclaimer {
        padding-top: 70px;
        padding-bottom: 70px;
    }
}