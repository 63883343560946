@import "../../../node_modules/bootstrap/scss/functions";
@import "../../../node_modules/bootstrap/scss/variables";
@import "../../../node_modules/bootstrap/scss/mixins";

@import '../../css/variables';
.component-table {
    &.horizontal {
        .heading-holder {
            font-family: 'Lato-Light';
            background-image: linear-gradient(to bottom, #98ca3e, #3ab44a);
            color: #ffffff;
            font-size: 1rem;
            padding: .5em 1em .6em;
            text-align: left;
        }
        .content-holder {
            .content-item {
                text-align: left;
                padding: 1.5em 1em 1.6em;
                border-bottom: 1px solid $main-text-color;
            }
        }
    }
    &.factors {
        .heading-holder {
            font-family: 'Lato-Light';
            background-image: linear-gradient(to bottom, #98ca3e, #3ab44a);
            color: #ffffff;
            font-size: 1rem;
            padding: .5em 1em .5em;
            text-align: center;
            margin-bottom: 1rem;
        }
        .content-holder {
            margin-bottom: 1.5rem;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: stretch;
            flex-wrap: wrap;
            .content-item {
                text-align: center;
                padding: .5em 1em;
                flex: 25% 1 1;
                max-width: 25%;
            }
            &.columns-auto {
                flex-direction: row;
                flex-wrap: wrap;
                display: flex;
                .content-item {
                    flex: 25% 1 1;
                    max-width: 25%;
                }
            }
            &.columns-1 {
                display: block;
                .content-item {
                    flex: 100% 1 1;
                    max-width: 100%;
                }
            }
        }
        & > *:last-child {
            margin-bottom: 0 !important;
        }
    }
    &.images {
        .heading-holder {
            padding-left: 7.5%;
        }
    }
}
@include media-breakpoint-up(sm) {
    .component-table {
        &.horizontal {
            .heading-holder {
                font-size: 1.35rem;
            }
            .content-holder {
                .content-item {
                    font-size: 1.35rem;
                }
            }
        }
        &.factors {
            .heading-holder {
                font-size: 1.35rem;
            }
            .content-holder {
                margin-bottom: 2rem;
                .content-item {
                    font-size: 1.35rem;
                }
                &.columns-sm-auto {
                    flex-direction: row;
                    flex-wrap: wrap;
                    display: flex;
                    .content-item {
                        flex: 25% 1 1;
                        max-width: 25%;
                    }
                }
                &.columns-sm-1 {
                    display: block;
                    .content-item {
                        flex: 100% 1 1;
                        max-width: 100%;
                    }
                }
                &.columns-sm-2 {
                    flex-direction: row;
                    flex-wrap: wrap;
                    display: flex;
                    .content-item {
                        flex: 50% 1 1;
                        max-width: 50%;
                       
                    }
                }
            }
        }
    }
}
@include media-breakpoint-up(md) {
    .component-table {
        &.factors {
            .content-holder {
                &.columns-md-auto {
                    flex-direction: row;
                    flex-wrap: wrap;
                    display: flex;
                    .content-item {
                        flex: 25% 1 1;
                        max-width: 25%;
                    }
                }
                &.columns-md-1 {
                    display: block;
                    .content-item {
                        flex: 100% 1 1;
                        max-width: 100%;
                    }
                }
                &.columns-md-2 {
                    flex-direction: row;
                    flex-wrap: wrap;
                    display: flex;
                    .content-item {
                        flex: 50% 1 1;
                        max-width: 50%;
                       
                    }
                }
            }
        }
    }
}
@include media-breakpoint-up(lg) {
    .component-table {
        &.factors {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: stretch;
            flex-wrap: wrap;
            .heading-holder {
                margin-bottom: 2rem;
            }
            .content-holder {
                border-right: 1px solid $main-text-color;
                &.no-border {
                    border: 0;
                }
                &.columns-lg-auto {
                    flex-direction: row;
                    flex-wrap: wrap;
                    display: flex;
                    .content-item {
                        flex: 25% 1 1;
                        max-width: 25%;
                    }
                }
                &.columns-lg-1 {
                    display: block;
                    .content-item {
                        flex: 100% 1 1;
                        max-width: 100%;
                    }
                }
                &.columns-lg-2 {
                    flex-direction: row;
                    flex-wrap: wrap;
                    display: flex;
                    .content-item {
                        flex: 50% 1 1;
                        max-width: 50%;
                       
                    }
                }
            }
            & > *:last-child {
                border-right: none !important;
            }
            &.columns-lg-3 {
                .heading-holder, .content-holder {
                    flex: 33.3% 1 1;
                    max-width: 33%;
                    &.span-2 {
                        flex: 66.6% 1 1;
                        max-width: 66%;
                    }
                }
            }
        }
        &.images {
            .heading-holder {
                padding-left: 0;
                flex: 0 0 48%;
                max-width: 48%;
                &:first-child {
                    max-width: 52%;
                    padding-right: 12px;
                    padding-left: 43px;
                    flex: 0 0 52%;
                }
            }
        }
    }
}
@include media-breakpoint-up(xl) {
    .component-table {
        &.factors {
            .content-holder {
                &.columns-xl-1 {
                    display: block;
                }
                &.columns-xl-2 {
                    flex-direction: row;
                    flex-wrap: wrap;
                    display: flex;
                    .content-item {
                        flex: 50% 1 1;
                        max-width: 50%;
                    }
                }
            }
        }
        &.images {
            .heading-holder {
                &:first-child {
                    padding-right: 14px;
                    padding-left: 48px;
                }
            }
        }
    }
}