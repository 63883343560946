@import "../../../node_modules/bootstrap/scss/functions";
@import "../../../node_modules/bootstrap/scss/variables";
@import "../../../node_modules/bootstrap/scss/mixins";

@import '../../css/variables';

.component-breadcrumb {
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 20px;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    max-width: 100%;
    .breadcrumb {
        margin-bottom: 0;
        border-radius: 0;
        background-color: transparent;
        padding: 0;
        .breadcrumb-item {
            font-family: 'BentonSans-Medium';
            font-size: .625rem;
            a {
                color: $main-second-text-color;
            }
            & + .breadcrumb-item {
                &:before {
                    content: '\003E';
                    color: $main-second-text-color;
                }
            }
            &:last-child {
                &:before {
                    color: $main-text-color;
                }
            }
        }
    }
}
@include media-breakpoint-up(sm) {
    .component-breadcrumb {
        max-width: 540px;
    }
}
@include media-breakpoint-up(md) {
    .component-breadcrumb {
        max-width: 720px;
    }
}
@include media-breakpoint-up(lg) {
    .component-breadcrumb {
        max-width: 960px;
    }
}
@include media-breakpoint-up(xl) {
    .component-breadcrumb {
        max-width: 1140px;
    }
}