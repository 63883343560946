@import "../../../node_modules/bootstrap/scss/functions";
@import "../../../node_modules/bootstrap/scss/variables";
@import "../../../node_modules/bootstrap/scss/mixins";

@import '../../css/variables';
@import '../../css/utils';

#component-topper {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    height: $topper-height;
    font-family: 'CeraPro-Medium';
    background-color: $main-link-color;

    a {
        display: inline-block;
        color: #ffffff;
        text-align: center;
        line-height: 1em;
        padding-bottom: 0.65rem;
        font-size: 0.65rem;

        &.read-more {
            font-size: 1rem !important;
            &:after {
                content: '\003E';
                padding-left: .35rem;
                display: inline-block;
                transform: translateX(0);
                transition: transform 1.3s ease-out;
            }
            &:hover {
                color: #ffffff;
                &:after {
                    animation-timing-function: ease-out;
                    animation-duration: 1.3s;
                    animation-iteration-count: infinite;
                    animation-name: read-more-animation;
                }
            }
        }
    }
}

@include media-breakpoint-up(sm) {
    #component-topper {
        a {
            padding-bottom: 0.5rem;
            font-size: 1rem;
            &.read-more {
                font-size: 1.1rem !important;
            }
        }
    }
}
