@import "../../../node_modules/bootstrap/scss/functions";
@import "../../../node_modules/bootstrap/scss/variables";
@import "../../../node_modules/bootstrap/scss/mixins";

@import '../../css/variables';
.component-jumbotron {
    height: auto;
    margin-bottom: 0;
    padding-top: 0;
    padding-bottom: 0;
    overflow: hidden;
    background-image: linear-gradient(to bottom, #98ca3c, #3ab449 96%);
    .container {
        min-height: 39vw;
        display: table;
        padding-left: 15px;
        padding-right: 15px;
        .jumbotron-content {
            display: table-cell;
            vertical-align: middle;
            padding-top: 40px;
            padding-bottom: 40px;
            h1 {
                color: #ffffff;
                font-family: 'Lato-Light';
                font-size: 2rem;
                letter-spacing: .005em;
                margin-bottom: 1rem;
                b {
                    font-family: 'Lato-Bold';
                    font-weight: normal;
                }
            }
            p {
                color: #ffffff;
                font-size: .8rem;
                font-family: 'Lato-Light';
            }
            & > *:last-child {
                margin-bottom: 0 !important;
            }
        }
    }
}
@include media-breakpoint-up(sm) {
    .component-jumbotron {
        .container {
            min-height: 210px;
            .jumbotron-content {
                h1 {
                    font-size: 2.5rem;
                }
                p {
                    font-size: 1rem;
                }
                & > * {
                    max-width: 90%;
                }
            }
        }
    }
}
@include media-breakpoint-up(md) {
    .component-jumbotron {
        .container {
            min-height: 201px;
            .jumbotron-content {
                h1 {
                    font-size: 3rem;
                }
            }
        }
    }
}
@include media-breakpoint-up(lg) {
    .component-jumbotron {
        .container {
            min-height: 268px;
            .jumbotron-content {
                h1 {
                    font-size: 3.5rem;
                }
                p {
                    font-size: 1.15rem;
                }
            }
        }
    }
}
@include media-breakpoint-up(xl) {
    .component-jumbotron {
        .container {
            min-height: 319px;
        }
    }
}